@charset "utf-8";

@import 'base/setting';
@import 'dots/dots';
@import 'plain/plugin/ie9/plain-ie9';

.hero {
  .container {
    padding-top: 62px;

    @include _mq(md){
      padding-top: 153px;
    }
  }
}

.header > .container,
.header__description,
.contact__list,
.contact__item,
.footer > .container,
.footer__nav,
.section-action,
.service__list,
.content__inner,
 {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.header__branding,
.header__tel,
.header__address,
.header__gnav,
.contact__item,
.footer__info,
.footer__nav-list,
.footer__map,
.section-action__item,
.service__item,
.content__main,
.content__sub
{
  display: table-cell;
  vertical-align: top;
}

.header__branding { width: 365px; }
.header__tel { width: 200px; }
.header__gnav { vertical-align: bottom; }
.footer__map { display: none; }
.content__main { width: 80%; }
.content__sub { width: 20%; }
